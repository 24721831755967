import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import Layout from '../components/Layout';

import SEO from '../components/SEO';

const Page = () => (
  <Layout>
    <SEO title="RLCF Monthly Book Reading Plan" />

    <h1>RLCF Monthly Book Reading Plan</h1>

    <h2>2023</h2>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Book</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>January</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/a-good-foundation">
                A Good Foundation
              </Link>{' '}
              (11)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>February</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-full-gospel">
                Full Gospel, The
              </Link>{' '}
              (25) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>March</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-full-gospel">
                Full Gospel, The
              </Link>{' '}
              (25) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>April</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/know-your-enemy">
                Know Your Enemy
              </Link>{' '}
              (7)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>May</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-purpose-of-failure">
                Purpose of Failure, The
              </Link>{' '}
              (3)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>June</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/knowing-gods-ways">
                Knowing God's Ways
              </Link>{' '}
              (22) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>July</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/knowing-gods-ways">
                Knowing God's Ways
              </Link>{' '}
              (22) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>August</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/gods-work-done-in-gods-way">
                God's Work Done In God's Way
              </Link>{' '}
              (22) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>September</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/gods-work-done-in-gods-way">
                God's Work Done In God's Way
              </Link>{' '}
              (22) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>October</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-congregation-the-club-and-the-church">
                Congregation the Club and the Church, The
              </Link>{' '}
              (9)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>November</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/fifty-marks-of-pharisees">
                Fifty Marks of Pharisees
              </Link>{' '}
              (51) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>December</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/fifty-marks-of-pharisees">
                Fifty Marks of Pharisees
              </Link>{' '}
              (51) - 2
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <h2>2024</h2>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Book</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>January</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/new-wine-in-new-wineskins">
                New Wine in New Wineskins
              </Link>{' '}
              (26) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>February</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/new-wine-in-new-wineskins">
                New Wine in New Wineskins
              </Link>{' '}
              (26) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>March</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/proverbs-versebyverse">
                Way of Wisdom, The
              </Link>{' '}
              (33) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>April</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/proverbs-versebyverse">
                Way of Wisdom, The
              </Link>{' '}
              (33) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>May</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-spirit-filled-life">
                Spirit-Filled Life, The
              </Link>{' '}
              (3)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>June</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/living-as-jesus-lived">
                Living as Jesus Lived
              </Link>{' '}
              (10)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>July</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/principles-of-serving-god">
                Principles of Serving God
              </Link>{' '}
              (3)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>August</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/gaining-gods-approval">
                Gaining God's Approval
              </Link>{' '}
              (12)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>September</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/beauty-for-ashes">
                Beauty for Ashes
              </Link>{' '}
              (5)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>October</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/secrets-of-victory">
                Secrets of Victory
              </Link>{' '}
              (16)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>November</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/practical-discipleship">
                Practical Discipleship
              </Link>{' '}
              (5)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>December</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/god-centred-praying">
                God-Centred Praying
              </Link>{' '}
              (10)
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <h2>2025</h2>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell>Book</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>January</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-supreme-priorities">
                Supreme Priorities, The
              </Link>{' '}
              (7)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>February</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-new-covenant-servant">
                New Covenant Servant, The
              </Link>{' '}
              (10)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>March</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/finding-gods-will">
                Finding God's Will
              </Link>{' '}
              (7)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>April</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-day-of-small-beginnings">
                Day of Small Beginnings, The
              </Link>{' '}
              (49) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>May</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-day-of-small-beginnings">
                Day of Small Beginnings, The
              </Link>{' '}
              (49) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>June</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/one-body-in-christ">
                One Body in Christ
              </Link>{' '}
              (7)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>July</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/hear-o-my-sons">
                Hear! O My Sons
              </Link>{' '}
              (63) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>August</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/hear-o-my-sons">
                Hear! O My Sons
              </Link>{' '}
              (63) - 2
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>September</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/hear-o-my-sons">
                Hear! O My Sons
              </Link>{' '}
              (63) - 3
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>October</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-lord-and-his-church">
                Lord and His Church, The
              </Link>{' '}
              (12)
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>November</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-final-triumph">
                Final Triumph, The
              </Link>{' '}
              (24) - 1
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>December</TableCell>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-final-triumph">
                Final Triumph, The
              </Link>{' '}
              (24) - 2
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <h2>Book List</h2>

    <h3>General Books</h3>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Book</TableCell>
            <TableCell># Chapters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/principles-of-serving-god">
                Principles of Serving God
              </Link>
            </TableCell>
            <TableCell>3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-purpose-of-failure">
                Purpose of Failure, The
              </Link>
            </TableCell>
            <TableCell>3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-spirit-filled-life">
                Spirit-Filled Life, The
              </Link>
            </TableCell>
            <TableCell>3</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/beauty-for-ashes">
                Beauty for Ashes
              </Link>
            </TableCell>
            <TableCell>5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/practical-discipleship">
                Practical Discipleship
              </Link>
            </TableCell>
            <TableCell>5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/finding-gods-will">
                Finding God's Will
              </Link>
            </TableCell>
            <TableCell>7</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/know-your-enemy">
                Know Your Enemy
              </Link>
            </TableCell>
            <TableCell>7</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/one-body-in-christ">
                One Body in Christ
              </Link>
            </TableCell>
            <TableCell>7</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-supreme-priorities">
                Supreme Priorities, The
              </Link>
            </TableCell>
            <TableCell>7</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-congregation-the-club-and-the-church">
                Congregation the Club and the Church, The
              </Link>
            </TableCell>
            <TableCell>9</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-new-covenant-servant">
                New Covenant Servant, The
              </Link>
            </TableCell>
            <TableCell>9</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/god-centred-praying">
                God-Centred Praying
              </Link>
            </TableCell>
            <TableCell>10</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/living-as-jesus-lived">
                Living as Jesus Lived
              </Link>
            </TableCell>
            <TableCell>10</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/a-good-foundation">
                Good Foundation, A
              </Link>
            </TableCell>
            <TableCell>11</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/gaining-gods-approval">
                Gaining God's Approval
              </Link>
            </TableCell>
            <TableCell>12</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-lord-and-his-church">
                Lord and His Church, The
              </Link>
            </TableCell>
            <TableCell>12</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/secrets-of-victory">
                Secrets of Victory
              </Link>
            </TableCell>
            <TableCell>16</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/gods-work-done-in-gods-way">
                God's Work Done In God's Way
              </Link>
            </TableCell>
            <TableCell>22</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/knowing-gods-ways">
                Knowing God's Ways
              </Link>
            </TableCell>
            <TableCell>22</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-final-triumph">
                Final Triumph, The
              </Link>
            </TableCell>
            <TableCell>24</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-full-gospel">
                Full Gospel, The
              </Link>
            </TableCell>
            <TableCell>25</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/new-wine-in-new-wineskins">
                New Wine in New Wineskins
              </Link>
            </TableCell>
            <TableCell>26</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/the-way-of-wisdom">
                Way of Wisdom, The
              </Link>
            </TableCell>
            <TableCell>33</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/fifty-marks-of-pharisees">
                Fifty Marks of Pharisees
              </Link>
            </TableCell>
            <TableCell>51</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

    <br />

    <h3>Targeted Books</h3>

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Book</TableCell>
            <TableCell># Chapters</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/encouragement-for-mothers">
                Encouragement for Mothers (Mothers)
              </Link>
            </TableCell>
            <TableCell>5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/a-girls-viewpoint">
                Girl's Viewpoint, A (Young Women)
              </Link>
            </TableCell>
            <TableCell>17</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/god-made-mothers">
                God Made Mothers (Mothers)
              </Link>
            </TableCell>
            <TableCell>22</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/god-speaks-through-animals">
                God Speaks Through Animals (Youth)
              </Link>
            </TableCell>
            <TableCell>24</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/a-heavenly-home">
                Heavenly Home, A (Marriage)
              </Link>
            </TableCell>
            <TableCell>5</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/neededmen-of-god">
                Needed - Men of God (Men)
              </Link>
            </TableCell>
            <TableCell>7</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/sex-love-marriage">
                Sex, Love and Marriage (Premarriage)
              </Link>
            </TableCell>
            <TableCell>10</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/a-spiritual-leader">
                Spiritual Leader, A (Leadership)
              </Link>
            </TableCell>
            <TableCell>16</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/through-the-bible">
                Through the Bible (Bible Study)
              </Link>
            </TableCell>
            <TableCell>67</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/what-the-lord-has-done-for-me">
                What the Lord Has Done for Me (Women)
              </Link>
            </TableCell>
            <TableCell>21</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Link href="https://www.cfcindia.com/books/woman-why-are-you-weeping">
                Woman, Why Are You Weeping? (Women)
              </Link>
            </TableCell>
            <TableCell>10</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Layout>
);

export default Page;
